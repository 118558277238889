<template>
  <div>
    <AppHeader title="财务账户"/>
    <Panel class="tabs search-panel" :title="false">
      <div slot="content">
        <div class="line">
          <div class="flex-dir-row mt10">
            <div class="flex-item-auto">
              <input class="line-input" id="keyword" v-model="keyword" placeholder="账户名称"/>
            </div>
            <div class="btn btn-small green ml5" @click="reset">重设</div>
            <div class="btn btn-small green ml5" @click="initAccounts">查询</div>
          </div>
        </div>
      </div>
    </Panel>
    <Panel class="tabs mt15" :title="false">
      <div slot="content" class="status-content" v-infinite-scroll="loadAccounts"
           infinite-scroll-disabled="loading"
           infinite-scroll-distance="10">
        <div class="line" v-for="(account,idx) of accounts" style="position: relative;overflow: hidden">
          <div :class="`member-status ${account.external?'external':'inner'}`">{{ account.external ? '外' : '内' }}</div>
          <div class="flex-dir-row">
            <div class="line-label">账户名称：</div>
            {{ account.name }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">账户类别：</div>
            {{ account.typeText }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">当前余额：</div>
            {{ account.amount }} 元
          </div>
          <div class="flex-dir-row">
            <div class="line-label">透支额度：</div>
            {{ account.allowOverdraftAmount }} 元
          </div>
          <div class="flex-dir-row">
            <div class="flex-dir-row flex-item-auto">
              <div class="line-label">账户状态：</div>
              {{ account.statusText }}
            </div>
            <div class="flex-dir-row">
              <div class="btn btn-xsmall green" @click="showAdjustDialog(account, 'CHARGE')">充值</div>
              <div class="btn btn-xsmall green ml5" @click="showAdjustDialog(account, 'DEDUCT')">扣款</div>
              <div class="btn btn-xsmall green ml5" @click="showAdjustDialog(account, 'ADJUST')">调账</div>
              <div class="btn btn-xsmall blue ml5" @click="showTzDialog(account)">透支</div>
            </div>
          </div>
        </div>
        <div class="line text-center" v-if="accounts.length===0">暂无记录</div>
      </div>
    </Panel>

    <mt-popup
        v-model="adjustVisible"
        position="bottom"
        v-if="adjustVisible"
    >
      <div class="popup-title">余额{{tsTypeText}}</div>
      <FileUpload space="payImg" max-size="1" ref="fileUpload" :editable="true" class="payImg"/>
      <div style="border-bottom: 1px solid #ddd; padding: 20px">
        <div class="flex-dir-row">
          <div class="popup-form-label">{{ tsTypeText }}账号</div>
          {{ curAccount.name }}
        </div>
        <div class="flex-dir-row mt10">
          <div class="popup-form-label">当前余额</div>
          {{ curAccount.amount }}
        </div>
        <div class="flex-dir-row mt10" v-if="tsType==='ADJUST'">
          <div class="popup-form-label">调账操作</div>
          <mt-radio
              title=""
              v-model="adjustType"
              :options="['减少', '增加']">
          </mt-radio>
        </div>
        <div class="flex-dir-row mt10">
          <div class="popup-form-label">{{ tsTypeText }}金额</div>
          <span v-if="sub" class="sub">-</span><input class="line-input line-input-amount" placeholder="填写金额" v-model="amountAddTest"/> 元
        </div>
        <div class="flex-dir-row mt10">
          <div class="popup-form-label">{{ tsTypeText }}后金额</div>
          {{ amountAfter }} 元
        </div>
        <div class="flex-dir-row mt10">
          <div class="popup-form-label">费用类型</div>
          <mt-checklist
              v-model="selectedTags"
              :options="payTags">
          </mt-checklist>
        </div>
        <div class="flex-dir-row mt10">
          <div class="popup-form-label"><span style="color: red">*</span>备注</div>
          <textarea class="flex-item-auto" rows="2" placeholder="备注" v-model="remark"></textarea>
        </div>
      </div>
      <div class="mint-msgbox-btns">
        <button class="mint-msgbox-btn mint-msgbox-cancel" @click="adjustVisible=false">取消</button>
        <button class="mint-msgbox-btn mint-msgbox-confirm" @click="doAdjust">确定</button>
      </div>
    </mt-popup>

    <mt-popup
        v-model="tzVisible"
        position="bottom"
        v-if="tzVisible"
    >
      <div class="popup-title">透支额度调整</div>
      <div style="border-bottom: 1px solid #ddd; padding: 20px">
        <div class="flex-dir-row">
          <input class="line-input" placeholder="填写金额" v-model="allowOverdraftAmount"/>
        </div>
      </div>
      <div class="mint-msgbox-btns">
        <button class="mint-msgbox-btn mint-msgbox-cancel" @click="tzVisible=false">取消</button>
        <button class="mint-msgbox-btn mint-msgbox-confirm" @click="doTz">确定</button>
      </div>
    </mt-popup>

  </div>
</template>

<style lang="scss">
.member-status {
  left: 0;
  top: 0;
  position: absolute;
  font-size: 10px;
  text-align: center;
  color: #fff;
  padding: 0 10px;
  opacity: .9;
  width: 50px;
  height: 20px;
  line-height: 20px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);

  &.external {
    background: orange;
  }

  &.inner {
    background: #3b5998;
  }
}

.line-input-amount {
  width: 110px;
}

.payImg {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 99999;
  display: inline-block;

  .grid-cell-1-3{
    width: 100px;
    height: 100px;
  }
}

.sub {
  color: red;
  font-size: 18px !important;
  padding-right: 5px;
}
</style>

<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {InfiniteScroll, DatetimePicker, Picker, MessageBox, Toast, Checklist} from 'mint-ui';
import {mapState} from 'vuex'
import Decimal from "decimal.js"
import FileUpload from "@/components/FileUpload";

const numReg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/

export default {
  components: {
    AppHeader,
    Panel,
    InfiniteScroll,
    DatetimePicker,
    Picker,
    FileUpload
  },
  data() {
    return {
      page: 1,
      accounts: [],
      loading: false,
      noMore: false,
      adjustVisible: false,
      keyword: '',
      curAccount: null,
      amountAddTest: null,
      remark: null,
      tsType: null,
      tsTypeText: null,
      payTags: [],
      selectedTags:[],
      tzVisible: false,
      allowOverdraftAmount: 0,
      adjustType: '减少'
    }
  },
  computed: {
    amountAdd() {
      if (this.amountAddTest) {
        if (numReg.test(this.amountAddTest)) {
          return this.sub? -this.amountAddTest : this.amountAddTest
        } else {
          Toast('无效的数字')
          return 0
        }
      }
      return 0
    },
    amountAfter() {
      if (this.curAccount) {
        return new Decimal(this.curAccount.amount).add(new Decimal(this.amountAdd))
      }
      return this.curAccount.amount
    },
    sub() {
      return this.tsType === 'DEDUCT' || (this.tsType === 'ADJUST' && this.adjustType === '减少')
    }
  },
  methods: {
    reset() {
      this.page = 1
      this.loading = false
      this.keyword = ''
    },
    resetAdjust() {
      this.remark = null
      this.amountAddTest = null
      this.curAccount = null
      this.allowOverdraftAmount = 0
      this.selectedTags = []
    },
    dateFormat(timestamp) {
      if (timestamp) {
        return this.$moment(timestamp).format('YYYY-MM-DD HH:mm')
      }
      return ''
    },
    initAccounts() {
      this.accounts = []
      this.page = 1
      this.noMore = false
      this.loadAccounts()
    },
    toYuan(fen) {
      return new Decimal(fen).div(new Decimal(100)).toFixed(2)
    },
    toFen(yuan) {
      return new Decimal(yuan).times(new Decimal(100)).toFixed(0)
    },
    wrapAccount(account) {
      let typeText
      switch (account.type) {
        case 'USER':
          typeText = '个人'
          break
        case 'COMPANY':
          typeText = '单位'
          break
        default :
          typeText = '课题组'
      }
      account.external = account.tags.indexOf('external') >= 0
      account.typeText = typeText
      account.statusText = account.status === 0 ? '开启' : '冻结'
      account.amount = this.toYuan(account.amount)
      account.allowOverdraftAmount = this.toYuan(account.allowOverdraftAmount)
      return account
    },
    loadAccounts() {
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('page', `${this.page}`)
      data.append('limit', '20')
      this.keyword && data.append('keyword', `${this.keyword}`)
      that.$http.post('/portal/pay/queryAccountBalance', data).then(res => {
        if (res.data && res.data.length > 0) {
          const accountsToAdd = res.data.map(acc => {
            return that.wrapAccount(acc)
          })
          that.accounts = that.accounts.concat(accountsToAdd)
          that.loading = false
          that.page = that.page + 1
        } else {
          this.noMore = true
        }
      })
    },
    preventDefault(e) {
      e.preventDefault()
    },
    showTzDialog(account) {
      this.curAccount = account
      this.allowOverdraftAmount = this.curAccount.allowOverdraftAmount
      this.tzVisible = true
    },
    doTz() {
      if (!numReg.test(this.allowOverdraftAmount)) {
        Toast("请输入有效数字")
        return
      }
      this.$http.post('/portal/pay/updateOverdraft', {
        "accountId": this.curAccount.id,
        "overdraft": this.allowOverdraftAmount
      },{
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
        if (res.success) {
          this.tzVisible = false
          Toast("调整成功！")
          this.initAccounts()
        }
      })
    },
    showAdjustDialog(account, tsType) {
      this.curAccount = account
      this.adjustVisible = true
      this.tsType = tsType
    },
    doAdjust() {
      if (this.amountAdd === 0) {
        Toast("请输入有效的值")
        return
      }
      const fids = this.$refs.fileUpload.getFids()
      if (fids.length === 0) {
        Toast("请上传图片")
        return
      }
      this.$http.post('/portal/pay/doPay', {
        "id": this.curAccount.id,
        "tsType": this.tsType,
        "amount": this.amountAdd,
        "remark": this.remark,
        "fid": fids[0],
        "tags": this.selectedTags.join(",")
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
        if (res.success) {
          this.adjustVisible = false
          Toast("调整成功！")
          this.initAccounts()
        }
      })
    },
    initPayTags() {
      const that = this
      this.$http.get('/api/dict?key=payTag&queryAll=false').then(res => {
        that.payTags = res.results.map(dict => {
          return {
            label: dict.text,
            value: dict.id
          }
        })
      })
    }
  },
  mounted() {
    this.initAccounts()
    this.initPayTags()
  },
  watch: {
    tzVisible: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.resetAdjust()
        }
      }
    },
    adjustVisible: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.resetAdjust()
        }
      }
    },
    tsType: {
      handler(newVal, oldVal) {
        if (newVal === 'CHARGE') {
          this.tsTypeText = '充值'
        } else if (newVal === 'DEDUCT') {
          this.tsTypeText = '扣款'
        } else {
          this.tsTypeText = '调账'
        }
      }
    }
  }
}
</script>
